import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/contract';
const wizardName = 'Service association';
const wizardObject = 'Service';
const wizardAction = 'serviceAssociation';

const ServiceAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const {doAction, item, errorMessage, errors, saving, success} = useDataProvider(dataProviderName, params.multipart);

    const {modelName} = existingModules

    const detailConfig = {
        "tabs": [
            {
                "name": "contract_service_association",
                "fieldsets": [
                    {
                        "name": "contract_service_association",
                        "fields": {
                            "available_services": {
                                "type": "sectiontitle",
                                "className":"col-12"
                            },
                            "associated": {
                                "type": "servicelist",
                                "relatedElements": {
                                    "assignedModel":"contract",
                                    "contract":item?.data,
                                    "legalEntity":item?.data?.legalEntity,
                                },
                                "defaultSelectedValues":[], 
                                "enableSelection":true, 
                                "disableMultipleRowSelection":true,
                                "dataProviderName":"cars/service",
                                "filters":{
                                    "items":[
                                        {
                                            "field":"employment_id",
                                            "operator":"isEmpty",
                                            "value":true
                                        },
                                        {
                                            "field":"car_id",
                                            "operator":"isEmpty",
                                            "value":true
                                        },
                                        {
                                            "field":"contract_id",
                                            "operator":"isEmpty",
                                            "value":true
                                        }
                                    ]
                                },
                                "className":"col-12",
                                "required": "true"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    
    const handleSave = async (data) => {
        doAction && await doAction({ action: 'serviceAssociation', model: dataProviderName, data: data });
        close();
    }

    return <>
        <DetailComponent
            values={{ "id" : item?.data?._id }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{identifier}}', { wizardObject:wizardObject, identifier: item.data.contractIdentifier })} 
                />
            }
            {...params}
        />
    </>
}

export default ServiceAssociation;


