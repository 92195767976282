import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Contract association';
const wizardObject = 'Contract';
const wizardAction = 'contractAssociation';

const ContractAssociation = (params) => {
    const { t } = useTranslation();

    const { userCapabilities, existingModules } = useContext(AppContext);

    

    // DATA PROVIDER
    const {doAction, item,  errorMessage, errors, saving, success} = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "association",
                "fieldsets": [
                    {
                        "name": "association",
                        "fields": {
                            "available_contracts": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "associated": {
                                "type": "contractlist",
                                "defaultSelectedValues": [],
                                "enableSelection": true,
                                "disableMultipleRowSelection": true,
                                "dataProviderName": "cars/contract",
                                "className": "col-12",
                                "required": "true",
                                "filters": {
                                    "items": [
                                        {
                                            "field": "car_id",
                                            "operator": "isEmpty",
                                            "value": true
                                        },
                                        {
                                            "field": "legal_entity_id",
                                            "operator": "equals",
                                            "value": item?.data?.legal_entity_id
                                        },
                                    ]
                                }
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success, editing, params, item.data])

  

    const handleSave = async (data) => {
        doAction && await doAction({ action: 'contractAssociation', model: dataProviderName, data: data });
    
    }

    return <>
        <DetailComponent
            values={{ "id" : item?.data?._id }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            onEditing={setEditing}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject:wizardObject, carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default ContractAssociation;


