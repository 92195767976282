//// LOCAL
import './mtska-frontend-app-scaffolding/styles/App/App.scss';
import { App } from './mtska-frontend-app-scaffolding';
import { DataProvider } from "src/mtska-frontend-data-provider";

const AppExecutive = () => {
    return (
        <DataProvider>
            <App />
        </DataProvider>
    )
}

export default AppExecutive;
