
import { useEffect } from 'react'

export const useLoadAll = ({ listFilterPageSort = {}, loadAll = () => { }, params = {}, reloadCounter }) => {
    useEffect(() => {
        const { filterModel = {}, paginationModel, sortModel } = listFilterPageSort
        const newFilterModel = { ...filterModel, ...params.filters }
        if (paginationModel && sortModel) {
            const { page, pageSize } = paginationModel
            loadAll(newFilterModel, sortModel, page + 1, pageSize)
        }
    }, [listFilterPageSort, params.filters, reloadCounter])
}