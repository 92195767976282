import React, { useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, List as ListComponent, useDrawer, Confirm, DrawerButton } from '../../../../mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import ImportButton from '../../Import/components/ImportButton';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks';

const dataProviderName = 'advertisings/source';
const drawerRouteBase = 'sources';
const moduleName = "Sources";

const List = (params) => {
    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);
    const { activate } = useDrawer();

    const apiRef = useGridApiRef();
    const getSelected = () => {
        return [...apiRef.current.getSelectedRows()].map(([name]) => name);
    };
    const [selected, setSelected] = useState([]);
    const handleRowSelection = (newSelection) => {
        setSelected(newSelection);
        params.rowSelectionExternalListener && params.rowSelectionExternalListener(newSelection);
    };

    const getFunctionalButtons = useMemo(() => {
        let rb = [];

        if (userCapabilities && userCapabilities[dataProviderName + '.delete'] && selected.length) {
            rb.push(
                <Button
                    disabled={userCapabilities && !userCapabilities[dataProviderName + '.delete']}
                    onClick={() => { handleDelete(); }}
                    label={t("Delete") + " (" + selected.length + ")"}
                    icon={"faTrash"}
                    variant="outlined"
                    size="small"
                    className="button-delete"
                />
            );
        }

        return rb;
    }, [selected, userCapabilities]);

    
    const [confirmDelete, setConfirmDelete] = useState();
    const handleDelete = () => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Delete")}
                okcallable={async () => {
                    doAction && await doAction({ action: 'destroy', model: dataProviderName, data: getSelected() });
                    setConfirmDelete(null);
                    apiRef.current.setRowSelectionModel([]);
                    loadAll();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Items elimination")}>
                {t("Are you sure to remove the selected elements?")}
            </Confirm>
        );
    }

    const { doAction, loadAll, items, total, loading, download, downloading, reloadCounter } = useDataProvider(dataProviderName);
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useLoadAll({ listFilterPageSort, loadAll, params, reloadCounter })

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', {id: row.id});
    }

    const handleDownload = () => {
        download()
    }

    return (
        <>
            {userCapabilities && userCapabilities[dataProviderName + '.list'] && (
                <ListComponent
                    {...params}
                    onRowDoubleClick={(userCapabilities && userCapabilities[dataProviderName + '.view']) ? handleRowDoubleclick : () => {}}
                    onFilterPageSortChange={handleFilterPageSortChange}
                    items={items}
                    total={total}
                    loading={loading}
                    apiRef={apiRef}
                    enableSelection
                    rightButtons={[
                        ...getFunctionalButtons,
                        <DrawerButton
                            drawerRoute={drawerRouteBase + '/create'}
                            className="button-new"
                            params={{ 
                                detailStack: params.detailStack ?? {},  
                                relatedElements: params.relatedElements ?? {} 
                            }}
                            label={t("New")}
                            disabled={userCapabilities && !userCapabilities[dataProviderName + '.create']}
                        />,
                        <ImportButton
                            dataProviderName={dataProviderName}
                            callback={(data) => {
                                loadAll();
                            }}
                            disabled={userCapabilities && !userCapabilities[dataProviderName + '.import']}
                        />,
                    ]}
                    dataProviderName={dataProviderName}
                    title={moduleName}
                    rowSelectionExternalListener={handleRowSelection}
                />
            )}
            {confirmDelete}
        </>
    );
};

export default List;

