import React, { useState, useEffect, useContext } from "react";
import { AppMenu, ActiveAppName, UserMenu, Debugger, LegalEntitySelector } from '../../../mtska-frontend-app-component';
import { AppContext } from '../../contexts';
import Minimizer from "./Minimizer";

const Aside = ({onOpenLayout}) => {

    const { appConfiguration } = useContext(AppContext);
    const [isOpen, setIsOpen] = useState();

    useEffect(() => {
        setIsOpen((localStorage.getItem('prefersAsideOpen') === 'true') ?? true);
        onOpenLayout();
    }, [])

    const [isMouseInside, setIsMouseInside] = useState(false);

    const handleIsOpen = () => {
        localStorage.setItem('prefersAsideOpen',!isOpen);
        setIsOpen(!isOpen);
        onOpenLayout();
    }

    const handleMouseEnter = () => {
        setIsMouseInside(true);
    }

    const handleMouseLeave = () => {
        setIsMouseInside(false);
    }

    return (
        <aside className={(isOpen) ? 'open' : 'closed'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <ActiveAppName />
            <Debugger />
            <LegalEntitySelector />
            <AppMenu />
            <UserMenu />

            <Minimizer onClick={handleIsOpen} visible={isMouseInside} open={isOpen}/>
        </aside>
    );
}

export default Aside;