import { addDays, format } from 'date-fns'

export const getEndAt = (watchedFields) => {
    const { contractDuration, start_at } = watchedFields

    if (start_at && contractDuration) {
        const startDate = new Date(start_at)
        const daysToAdd = parseInt(contractDuration, 10)
        const endDate = addDays(startDate, daysToAdd)
        return format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'")
    }
}

export const getTotalLeaseFee = (watchedFields) => {
    const {
        advanceFee = 0,
        deposit = 0,
        financialFee = 0,
        fuelFee = 0,
        fuelIncluded,
        insuranceFee = 0,
        insuranceIncluded,
        serviceFee = 0,
        taxFee = 0,
        taxIncluded
    } = watchedFields

    return (
        Number(advanceFee) +
        Number(deposit) +
        Number(financialFee) +
        (fuelIncluded === 'yes' ? Number(fuelFee) : 0) +
        (insuranceIncluded === 'yes' ? Number(insuranceFee) : 0) +
        Number(serviceFee) +
        (taxIncluded === 'yes' ? Number(taxFee) : 0)
    )
}

export const getEmissionsForFringeBenefit = (watchedFields, emissionsManual, setup) => {
    const { emissionsProvider } = watchedFields

    if (emissionsProvider) {
        if (emissionsProvider == 'manual') {
            return emissionsManual
        } else if (emissionsProvider == 'setup_emissions' && setup) {
            return setup.emissions;
        }
    }
}

export const getFringeBenefitFields = (watchedFields, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency) => {
    const {
        fbProvider
    } = watchedFields

    if (fbProvider) {
        if (fbProvider == 'manual') {
            return {
                fbModelSetupProvider: fbModelSetupProvider,
                fbMonthly: fbMonthly,
                fbAnnual: fbAnnual,
                fbCurrency: fbCurrency
            }
        } else if (fbProvider == 'aci' && emissionsFinal && fbFringeBenefitCar) {

            let fbAnnualFinal;
            switch (true) {
                case parseInt(emissionsFinal) < 60:
                    fbAnnualFinal = fbFringeBenefitCar.fringeBenefitValue.value25;
                    break;
                case parseInt(emissionsFinal) < 160:
                    fbAnnualFinal = fbFringeBenefitCar.fringeBenefitValue.value30;
                    break;
                case parseInt(emissionsFinal) < 190:
                    fbAnnualFinal = fbFringeBenefitCar.fringeBenefitValue.value50;
                    break;
                default:
                    fbAnnualFinal = fbFringeBenefitCar.fringeBenefitValue.value60;
                    break;
            }

            return {
                fbModelSetupProvider: fbFringeBenefitCar.make+" "+fbFringeBenefitCar.model+" "+fbFringeBenefitCar.setup,
                fbMonthly: parseInt(fbAnnualFinal / 12 * 100) / 100,
                fbAnnual: fbAnnualFinal,
                fbCurrency: fbCurrency
            }
        }
    }
}