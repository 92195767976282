import { useEffect } from 'react'
import { getEndAt, getTotalLeaseFee, getEmissionsForFringeBenefit, getFringeBenefitFields } from './detail.utils'

export const useFieldWatcher = ({ setValue, watchedFields }) => {

    const {
        advanceFee = 0,
        contractDuration,
        deposit = 0,
        financialFee = 0,
        fuelFee = 0,
        fuelIncluded,
        insuranceFee = 0,
        insuranceIncluded,
        serviceFee = 0,
        start_at,
        taxFee = 0,
        taxIncluded,

        emissionsProvider,
        emissionsManual,
        setup,

        fbProvider,
        emissionsFinal,
        fbFringeBenefitCar,
        fbModelSetupProvider,
        fbMonthly,
        fbAnnual,
        fbCurrency,
    } = watchedFields

    const endAtWatchedFields = { contractDuration, start_at }
    const totalLeaseFeeWatchedFields = {
        advanceFee,
        deposit,
        financialFee,
        fuelFee,
        fuelIncluded,
        insuranceFee,
        insuranceIncluded,
        serviceFee,
        taxFee,
        taxIncluded
    }
    const emissionsForFringeBenefitFields = { emissionsProvider }
    const fringeBenefitFields = { fbProvider }

    useEndAt({ setValue, watchedFields: endAtWatchedFields })
    useTotalLeaseFee({ setValue, watchedFields: totalLeaseFeeWatchedFields })
    useEmissionsForFringeBenefit({ setValue, watchedFields: emissionsForFringeBenefitFields, emissionsManual, setup })

    useFringeBenefitFields({ setValue, watchedFields: fringeBenefitFields, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency })
}

const useEndAt = ({ setValue, watchedFields }) => {

    useEffect(() => {
        const endAt = getEndAt(watchedFields)
        setValue('end_at', endAt)
    }, [...Object.values(watchedFields)])

}

const useTotalLeaseFee = ({ setValue, watchedFields }) => {

    useEffect(() => {
        const totalLeaseFee = getTotalLeaseFee(watchedFields)
        setValue('totalLeaseFee', totalLeaseFee)
    }, [...Object.values(watchedFields)])

}

const useEmissionsForFringeBenefit = ({ setValue, watchedFields, emissionsManual, setup }) => {

    useEffect(() => {
        const emissionsFinal = getEmissionsForFringeBenefit(watchedFields, emissionsManual, setup);

        setValue('emissionsFinal', emissionsFinal)
    }, [...Object.values(watchedFields)])

}

const useFringeBenefitFields = ({ setValue, watchedFields, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency}) => {

    useEffect(() => {
        const fringeBenefitFields = getFringeBenefitFields(watchedFields, emissionsFinal, fbFringeBenefitCar, fbModelSetupProvider, fbMonthly, fbAnnual, fbCurrency);

        if (fringeBenefitFields ==  undefined) return;

        setValue('fbAnnualFinal', fringeBenefitFields.fbAnnual);
        setValue('fbMonthlyFinal', fringeBenefitFields.fbMonthly);
        setValue('fbModelSetupProviderFinal', fringeBenefitFields.fbModelSetupProvider);
        setValue('fbCurrencyFinal', fringeBenefitFields.fbCurrency);

    }, [...Object.values(watchedFields)])

}