import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer, useRelationshipInjector, useDetailConfigOverrider } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'opportunities/lead';
const model = 'opportunities/lead';
const modelExposedName = 'Lead';

const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);
    const { injectToDetail } = useRelationshipInjector();
    const { overrideDetailFields } = useDetailConfigOverrider();
    // DATA PROVIDER
    const { doAction, item, loadOne, loadAll, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName
    const detailConfig = (params.detailConfigOverride) ? overrideDetailFields(existingModules[dataProviderName].detailConfig, params.detailConfigOverride) : existingModules[dataProviderName].detailConfig;
    useEffect(() => {
        loadOne(params.filters?._id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    
    const handleSave = async (data) => {
        doAction && doAction({ action: 'create', model: model ? model : dataProviderName, data: data });
    }

    injectToDetail(item?.data, 'legalEntity', params.legalEntity);
    injectToDetail(item?.data, 'employment', params.employment);
    injectToDetail(item?.data, 'registered_at', new Date().toISOString());
    injectToDetail(item?.data, 'assigned_at', new Date().toISOString());
    injectToDetail(item?.data, 'propertyID', 'W-');
    injectToDetail(item?.data, 'status', 'new');
    injectToDetail(item?.data, 'product', 'buy');

    return <>
        <DetailComponent
            values={{
                ...item?.data,
                // legalEntity:params.legalEntity,
                // employment:params.employment,
                // registered_at:new Date().toISOString(),
                // assigned_at:new Date().toISOString(),
                // propertyID:'W-iiiiii',
                // status:'new',
                // product:'buy',
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', {itemname:t(modelExposedName)})}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', {itemname:t(modelExposedName)})}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', {itemname:t(modelExposedName)})}
                />
            }
            {...params}
        />
    </>
}

export default Create;


