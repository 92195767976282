import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/service';
const model = 'cars/service';
const modelExposedName = 'Service';

const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);;

    

    // DATA PROVIDER
    const { doAction, item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);

    const {modelName} = existingModules[dataProviderName]

    const detailConfig = {
        "tabs": [
            {
                "name": "create_new_service",
                "fieldsets": [
                    {
                        "name": "service_information",
                        "fields": {
                            "name": {
                                "type": "text",
                                "required": true,
                                "className": "col-6",
                                "icon": "faInputText"
                            },
                            "identifier": {
                                "type": "text",
                                "required": true,
                                "className": "col-6",
                                "icon": "faFingerprint",
                                "label": "serviceIdentifier"
                            },
                            "type": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "fuelcard",
                                        "value": "fuelcard"
                                    },
                                    {
                                        "label": "tollsystem",
                                        "value": "tollsystem"
                                    },
                                    {
                                        "label": "lta",
                                        "value": "lta"
                                    },
                                    {
                                        "label": "courtesycar",
                                        "value": "courtesycar"
                                    },
                                    {
                                        "label": "roadsideassistance",
                                        "value": "roadsideassistance"
                                    },
                                    {
                                        "label": "tyre",
                                        "value": "tyre"
                                    }
                                ],
                                "required": true,
                                "className": "col-6",
                                "icon": "faChartNetwork"
                            },
                            "status": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "active",
                                        "value": "active"
                                    },
                                    {
                                        "label": "inactive",
                                        "value": "inactive"
                                    }
                                ],
                                "required": true,
                                "className": "col-6",
                                "icon": "faChartPyramid"
                            }
                        }
                    },
                    {
                        "name": "service_budget",
                        "fields": {
                            "dailyLimit": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faMoneyBill"
                            },
                            "monthlyLimit": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faMoneyBill"
                            },
                            "yearlyLimit": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faMoneyBill"
                            },
                            "lifetimeLimit": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faMoneyBill"
                            }
                        },
                        "visibilitycondition": [
                            [
                                "type",
                                "in",
                                [
                                    "fuelcard",
                                    "tollsystem",
                                    "lta"
                                ]
                            ]
                        ]
                    },
                    {
                        "name": "courtesy_car_information",
                        "fields": {
                            "courtesycarSegment": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "cityCar",
                                        "value": "A"
                                    },
                                    {
                                        "label": "smallCar",
                                        "value": "B"
                                    },
                                    {
                                        "label": "compactCar",
                                        "value": "C"
                                    },
                                    {
                                        "label": "largeCar",
                                        "value": "D"
                                    },
                                    {
                                        "label": "prestigeSedan",
                                        "value": "E"
                                    },
                                    {
                                        "label": "luxuryCar",
                                        "value": "F"
                                    },
                                    {
                                        "label": "suv",
                                        "value": "G/J"
                                    },
                                    {
                                        "label": "minivan",
                                        "value": "M"
                                    },
                                    {
                                        "label": "sportsOrCoupe",
                                        "value": "S"
                                    }
                                ],
                                "className": "col-6",
                                "icon": "faCar"
                            },
                            "courtesycarAvailability": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "immediate",
                                        "value": ">0"
                                    },
                                    {
                                        "label": "starting4hours",
                                        "value": ">4"
                                    },
                                    {
                                        "label": "starting8hours",
                                        "value": ">8"
                                    }
                                ],
                                "className": "col-6",
                                "icon": "faHourglass"
                            }
                        },
                        "visibilitycondition": [
                            [
                                "type",
                                "=",
                                "courtesycar"
                            ]
                        ]
                    },
                    {
                        "name": "tyres_information",
                        "fields": {
                            "tyreIdentifier": {
                                "type": "text",
                                "className": "col-3",
                                "icon": "faFingerprint",
                                "label": "tyresetIdentifier"
                            },
                            "tyreMake": {
                                "type": "text",
                                "className": "col-3",
                                "icon": "faInputText"
                            },
                            "tyreModel": {
                                "type": "text",
                                "className": "col-3",
                                "icon": "faInputText"
                            },
                            "tyreNumber": {
                                "type": "number",
                                "className": "col-3",
                                "icon": "faInputNumber",
                                "label": "number_of_tyres_in_set"
                            },
                            "tyreType": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "allSeason",
                                        "value": "allSeason"
                                    },
                                    {
                                        "label": "winter",
                                        "value": "winter"
                                    },
                                    {
                                        "label": "summer",
                                        "value": "summer"
                                    }
                                ],
                                "className": "col-4",
                                "icon": "faChartNetwork"
                            },
                            "tyreCategory": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "performance",
                                        "value": "performance"
                                    },
                                    {
                                        "label": "premium",
                                        "value": "premium"
                                    }
                                ],
                                "className": "col-4",
                                "icon": "faChartSimpleHorizontal"
                            },
                            "tyreStatus": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "stored",
                                        "value": "stored"
                                    },
                                    {
                                        "label": "installed",
                                        "value": "installed"
                                    },
                                    {
                                        "label": "terminated",
                                        "value": "terminated"
                                    },
                                    {
                                        "label": "ordered",
                                        "value": "ordered"
                                    }
                                ],
                                "className": "col-4",
                                "icon": "faChartPyramid"
                            },
                            "tyreOrdered_at": {
                                "type": "date",
                                "className": "col-3",
                                "icon": "faCalendarPen",
                                "label": "tyre_ordered_at"
                            },
                            "tyreStored_at": {
                                "type": "date",
                                "className": "col-3",
                                "icon": "faCalendarPlus",
                                "label": "tyre_stored_at"
                            },
                            "tyreInstalled_at": {
                                "type": "date",
                                "className": "col-3",
                                "icon": "faCalendarHeart",
                                "label": "tyre_installed_at"
                            },
                            "tyreTerminated_at": {
                                "type": "date",
                                "className": "col-3",
                                "icon": "faCalendarXmark",
                                "label": "tyre_terminated_at"
                            },
                            "tyreStorageLocation": {
                                "type": "textarea",
                                "minrows": 6,
                                "className": "col-12",
                                "icon": "faLocationPin"
                            }
                        },
                        "visibilitycondition": [
                            [
                                "type",
                                "=",
                                "tyre"
                            ]
                        ]
                    },
                    {
                        "name": "administrative_information",
                        "fields": {
                            "legalEntity": {
                                "type": "relationshipSingle",
                                "required": true,
                                "relation": {
                                    "drawerRoute": "legalentities",
                                    "filter": {
                                        "items": [
                                            {
                                                "field": "type",
                                                "operator": "equals",
                                                "value": "customer"
                                            }
                                        ]
                                    }
                                },
                                "view": "legalEntity",
                                "label": "customer"
                            },
                            "issuer": {
                                "type": "relationshipSingle",
                                "required": true,
                                "relation": {
                                    "drawerRoute": "legalentities",
                                    "filter": {
                                        "items": [
                                            {
                                                "field": "type",
                                                "operator": "isAnyOf",
                                                "value": [
                                                    "supplier",
                                                    "public_authority"
                                                ]
                                            }
                                        ]
                                    }
                                },
                                "view": "legalEntity"
                            },
                            "broker": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "legalentities",
                                    "filter": {
                                        "items": [
                                            {
                                                "field": "type",
                                                "operator": "isAnyOf",
                                                "value": [
                                                    "renter",
                                                    "partner"
                                                ]
                                            }
                                        ]
                                    }
                                },
                                "view": "legalEntity"
                            }
                        }
                    },
                    {
                        "name": "assignment",
                        "fields": {
                            "assignedModel": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "employee",
                                        "value": "employee"
                                    },
                                    {
                                        "label": "car",
                                        "value": "car"
                                    },
                                    {
                                        "label": "contract",
                                        "value": "contract"
                                    }
                                ],
                                "className": "col-12"
                            },
                            "assignment_start_at": {
                                "type": "date",
                                "className": "col-6",
                                "icon": "faCalendarHeart"
                            },
                            "assignment_end_at": {
                                "type": "date",
                                "className": "col-6",
                                "icon": "faCalendarXmark"
                            },
                            "employment": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "employments"
                                },
                                "view": "employment",
                                "label": "assigned_to_employee",
                                "visibilitycondition": [
                                    [
                                        "assignedModel",
                                        "=",
                                        "employee"
                                    ]
                                ]
                            },
                            "car": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "cars"
                                },
                                "view": "car",
                                "label": "assigned_to_car",
                                "visibilitycondition": [
                                    [
                                        "assignedModel",
                                        "=",
                                        "car"
                                    ]
                                ]
                            },
                            "contract": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "contracts"
                                },
                                "view": "contract",
                                "label": "assigned_to_contract",
                                "visibilitycondition": [
                                    [
                                        "assignedModel",
                                        "=",
                                        "contract"
                                    ]
                                ]
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    useEffect(() => {
        loadOne(params.filters?._id);
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success]);

    
    const handleSave = async (data) => {
        doAction && doAction({ action: 'create', model: model ? model : dataProviderName, data: data });
    }

    return <>
        <DetailComponent
            values={{
                ...item?.data,
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', {itemname:t(modelExposedName)})}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', {itemname:t(modelExposedName)})}
                    subtitle={t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', {itemname:t(modelExposedName)})}
                />
            }
            {...params}
        />
    </>
}

export default Create;


