import React, {useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Service association';
const wizardObject = 'Service';
const wizardAction = 'serviceAssociation';

const ServiceAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


     // DATA PROVIDER
     const {
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        doAction
    } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        loadOne(params?.car?.id);
    }, []);

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "association",
                "fieldsets": [
                    {
                        "name": "association",
                        "fields": {
                            "available_services": {
                                "type": "sectiontitle",
                                "className":"col-12"
                            },
                            "associated": {
                                "type": "servicelist",
                                "relatedElements": {
                                    "assignedModel":"car",
                                    "car":item?.data,
                                    "legalEntity":item?.data?.legalEntity,
                                },
                                "defaultSelectedValues":[],
                                "enableSelection":true, 
                                "disableMultipleRowSelection":true,
                                "dataProviderName":"cars/service",
                                "filters":{
                                    "items":[
                                        {
                                            "field":"employment_id",
                                            "operator":"isEmpty",
                                            "value":true
                                        },
                                        {
                                            "field":"car_id",
                                            "operator":"isEmpty",
                                            "value":true
                                        },
                                        {
                                            "field":"contract_id",
                                            "operator":"isEmpty",
                                            "value":true
                                        }
                                    ]
                                },
                                "className":"col-12",
                                "required": "true"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }
    const handleSave = async (data) => {
        doAction && await doAction({ action: 'serviceAssociation', model: dataProviderName, data: data }).then();
        close();
    }

    return <>
        <DetailComponent
            values={{ "id" : item?.data?._id }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject:wizardObject, carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default ServiceAssociation;


