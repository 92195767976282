import axios from 'axios';
import authHeader from './authHeader';
import { handleError, handleSuccess } from './handleResponse';

const endpointBase = process.env.REACT_APP_API_ENDPOINT || window.location.origin + '/api';

const createService = (endpointPart, multipart) => {

    const baseUrl = endpointBase ;
    const legalEntityId = localStorage.getItem('filterByLegalEntity');

    const endpoint = baseUrl + '/' + endpointPart

    async function remove(id) {
        const axiosRequestOptions = {
            url: endpoint + '/' + id + '?le=' + legalEntityId,
            method: 'DELETE',
            headers: authHeader()
        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError);
    }

    async function upload(files) {

        const formData = new FormData();
        formData.append("file", files[0]);

        let method = 'POST';

        const axiosRequestOptions = {
            url: endpoint,
            method: method,
            headers: authHeader(),
            data: formData

        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError)

    }

    async function save(data) {


        let method = 'POST';
        let urlId = '';

        if (data.id !== undefined && !multipart) {
            method = 'PUT';
            urlId = '/' + data.id;
        }

        // if (data.id !== undefined && multipart) {
        //     data._method = 'PUT';
        // }

        urlId += '?le=' + legalEntityId

        const axiosRequestOptions = {
            url: endpoint + urlId,
            method: method,
            headers: authHeader(multipart),
            data: data

        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError)

    }

    async function doAction(data) {


        let method = 'POST';
        let urlId = '';


        urlId += '/action';

        const axiosRequestOptions = {
            url: endpoint + urlId,
            method: method,
            headers: authHeader(multipart),
            data: data

        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError)

    }

    async function download() {

        const axiosRequestOptions = {
            url: endpoint + '/export',
            method: 'GET',
            headers: authHeader(),
            responseType: 'blob',
        };

        return axios(axiosRequestOptions).then(res => {
            console.log('am i downloading?', res)
            const blob = new Blob([res.data], { type: res.data.type })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            const contentDisposition = res.headers['content-disposition'];
            console.info('contentDisposition', contentDisposition);

            const fileName =  res.headers['content-disposition'].split('filename=')[1]

            
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
          }).catch(err => {
            console.log('or do i have an error?', err)
          })

    }

    async function getAll(filterModel = {}, sortModel = {}, page = '', per_page = '') {

        const axiosRequestOptions = {
            url: endpoint,
            method: 'GET',
            headers: authHeader(),
            // signal: controller.signal,
            params: {
                page: page,
                per_page: per_page,
                filters: filterModel,
                sort: sortModel,
                le: legalEntityId
            }
        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError);
    }

    async function getById(id) {

        const axiosRequestOptions = {
            url: endpoint + '/' + id,
            method: 'GET',
            headers: authHeader(),
            params: {
                le: legalEntityId
            }
        };

        const res = axios(axiosRequestOptions).then(handleSuccess).catch(handleError);

        return res
    }

    return {
        remove,
        save,
        doAction,
        getAll,
        getById,
        upload,
        download,
    }
}


export default createService;