//import '../styles/App/App.scss';
import React, { useContext } from "react";
import { Auth0Provider } from '@auth0/auth0-react'
import { Routes } from '../routes';
import { AppContext } from '../contexts';
import { DrawerProvider } from '../../mtska-frontend-app-component';

const ContextualizedApp = () => {

    const { appConfiguration } = useContext(AppContext);
    
    return (
        <Auth0Provider
            domain={appConfiguration.auth0.DOMAIN}
            clientId={appConfiguration.auth0.CLIENT_ID}
            authorizationParams={{
                redirect_uri: appConfiguration.auth0.REDIRECT_URI,
            }}
        >
                <DrawerProvider modules={appConfiguration.modules.active}  >
                    <Routes modules={appConfiguration.modules.active} />
                </DrawerProvider>
        </Auth0Provider>
    )
}

export default ContextualizedApp;
