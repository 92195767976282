import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Pool association';
const wizardAction = 'poolAssociation';

const PoolAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();

    const { userCapabilities, existingModules } = useContext(AppContext);


    
     // DATA PROVIDER
     const {
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        doAction,
        success,
        editing,
    } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        loadOne(params?.car?.id);
    }, []);
    
    const modelName = existingModules.modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "association",
                "fieldsets": [
                    {
                        "name": "association",
                        "fields": {
                            "available_pool": {
                                "type": "sectiontitle",
                                "className":"col-12"
                            },
                            "associated": {
                                "type": "poollist",
                                "defaultSelectedValues":[],
                                "enableSelection":true,
                                "disableMultipleRowSelection":true,
                                "dataProviderName":"cars/pool",
                                "filters":{
                                    "items":[
                                        {
                                            "field":"isPool",
                                            "operator":"equals",
                                            "value":"true"
                                        }
                                    ]
                                },
                                "className": "col-12",
                                "required": "true"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = async (data) => {
        doAction && await doAction({ action: 'poolAssociation', model: dataProviderName, data: data }).then();
    }

    return <>
        <DetailComponent
            values={{ "id" : item?.data?._id }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Add {{carIdentifier}} to a Pool as a shared vehicle', { carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default PoolAssociation;


