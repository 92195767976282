import React, { useContext } from 'react';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { Full, Minimal } from '../views';
import { AppContext } from '../contexts';

const Routes = ({ modules = [] }) => {

    const { appConfiguration } = useContext(AppContext);

    const protectedLoader = ({ request }) => {
        if (!sessionStorage.getItem('token')) {
            let params = new URLSearchParams();
            params.set("from", new URL(request.url).pathname);
            return redirect("/?" + params.toString());
        }
        return null;
    }

    const publicLoader = ({ request }) => {
        if (sessionStorage.getItem('token')) {
            return redirect(appConfiguration.auth0.DEFAULT_AUTHENTICATED_URI);
        }
        return null;
    }

    const rs = [
        {
            path: "/",
            Component: Minimal,
            loader: publicLoader
        },
    ];
    
    Object.entries(modules).forEach(([key, m]) => {
        m.routes.map((r, j) => {
            if (r.protected) {
                r.Component = Full
                r.loader = protectedLoader
            } else {
                r.Component = Minimal
                r.loader = publicLoader
            }
            //console.log('module '+m.name, r.path)
            rs.push(r)
        })
    });

    const router = createBrowserRouter(rs);

    return <RouterProvider router={router} />
}

export default Routes;
