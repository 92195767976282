import React, {useEffect, useContext, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Driver association';
const wizardObject = 'Driver';
const wizardAction = 'personAssociation';

const PersonAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const {
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        success,
        doAction
    } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        loadOne(params?.car?.id);
    }, []);


    const modelName= existingModules.modelName
    const detailConfig={
        "tabs": [
            {
                "name": "association",
                "fieldsets": [
                    {
                        "name": "association",
                        "fields": {
                            "person_information": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "assignment_start_at": {
                                "type": "date",
                                "className": "col-6",
                                "placeholder": "insert"
                            },
                            "assignment_end_at": {
                                "type": "date",
                                "className": "col-6",
                                "placeholder": "insert"
                            },
                            "associated": {
                                "type": "personlist",
                                "defaultSelectedValues":[],
                                "enableSelection":true,
                                "disableMultipleRowSelection":true,
                                "dataProviderName":"persons/person",
                                "className": "col-12",
                                "required": "true"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    const [editing, setEditing] = useState(false)


    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = async (data) => {
        doAction && await doAction({ action: 'personAssociation', model: dataProviderName, data: data });
    }

    const driver = item.data.pools?.map((pool) => pool.employment?.person)[0];
    
    return <>
        <DetailComponent
            values={{ "id" : item?.data?._id }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            onEditing={setEditing}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            alertMessage={(item.data.isPool == 'false' && typeof driver != 'undefined') && t('This operation will terminate the actual assignment to {{firstname}} {{lastname}}', {firstname:driver.firstname, lastname:driver.lastname})}
            canSave={userCapabilities && userCapabilities[dataProviderName+'.'+wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject:wizardObject, carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default PersonAssociation;


